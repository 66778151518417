import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  withStyles
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from './../utils/API';
import auth from './../auth/auth';
import PasswordInput from './PasswordInput';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color = "inherit"
      href = "http://www.citycash.in/" >
        Citycash
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      data: {
        username: null,
        password: null,
      },
      errors: {
        username: '',
        password: '',
      },
      open: false,
      transition: undefined, 
    }
  }

  handleClick = Transition => {
    this.setState({
      transition: Transition
    });
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => {
        val === null && (valid = false)
      }
    );
    return valid;
  }

  login = (event) => {
    event.preventDefault();
    if (event.target.username.value == null || 
      event.target.username.value === undefined || 
      event.target.username.value.length === 0){
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          'username': 'Enter the username.'
        }
      }));
    } 
    
    if (event.target.password.value == null ||
      event.target.password.value === undefined ||
      event.target.password.value.length === 0){
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          'password': 'Enter password.'
        }
      }));
    }

    if (this.validateForm(this.state.data)) {
      const data = {
        username: this.state.data.username,
        password: this.state.data.password
      };

    API.post('appapi/field-login', data)
      .then((res) => {
        if(res.data.responseCode === 200){
          if (typeof (Storage) !== "undefined") {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('urlToken', res.data.urltoken);
            auth.login(() => {
              this.props.history.push("/call");
            });
          } else {
            document.getElementById("result").innerHTML = "Sorry, your browser does not support web storage...";
          }
        }else{
          console.log(">>>>>>>>>>");
          this.handleClick(TransitionUp);
        }
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      })
    }
  }

  onChange(event){
    const { name, value } = event.target;
    let errorMessage = "";
    switch (name){
      case 'username':
        errorMessage = value.length < 1 ? 'Enter the username.' : ''
        break;
      case 'password':
        errorMessage = value.length < 1 ? 'Enter password.' : ''
        break;
      default:
        break;
    }

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  }

  render() {
    const {
      classes
    } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src = "./images/white_logo.png"
          alt = "Citycash Logo"
          height = "50"
          width = "150" / >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={this.login} noValidate>
            <TextField
              margin="normal"
              required
              error={this.state.errors.username.length === 0 ? false : true}
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete="text"
              helperText = {this.state.errors.username}
              variant= "outlined"
              autoFocus
              onChange={
                this.onChange.bind(this)
              }
            />

            <PasswordInput
              label="Password"
              name="password"
              fullWidth
              margin = "normal"
              key="password"
              variant = "outlined"
              autoComplete = "current-password"
              value={this.state.password}
              required
              helperText={this.state.errors.password}
              error = {
                this.state.errors.password.length === 0 ? false : true
              }
              onChange={this.onChange.bind(this)}
            />
           
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        <Snackbar
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={this.transition}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Invalid Credentials.</span>}
        />
      </Container>
    )
  }
}

export default withStyles(useStyles)(Login)