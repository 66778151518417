import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
    withStyles
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from './../utils/API';
// Custom Component
import AppDrawer from './AppDrawer';
import auth from './../auth/auth';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Call extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            data: {
                phonenumber: null,
            },
            errors: {
                phonenumber: ''
            },
            open: false,
            transition: undefined,
            searchphone: []
        }
    }

    handleClick = Transition => {
        this.setState({
            transition: Transition
        });
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    validateForm = (phonenumber) => {
        let valid = true;
        if (phonenumber == null ||
            phonenumber === undefined ||
            phonenumber.length !== 10) {
            valid = false;
        }
        return valid;
    }

    selectChange(event) {
        // console.log(">>>>>> " + JSON.stringify(event));
        // let errorMessage = "";
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                "phonenumber": '',
            }
        }));
    }

    onChange(event) {
        const {
            name,
            value
        } = event.target;
        
        let errorMessage = "";
        switch (name) {
            case 'phonenumber':
                errorMessage = value.length === 10 ? '' : 'Enter the mobile number.'
                break;
            default:
                break;
        }

        if(value.length <= 10){
            const data = {
                phone_no: value,
                token: localStorage.token
            };
    
            API.post('appapi/agent-phone-auto', data)
                    .then((res) => {
                        this.setState({
                            searchphone: res.data
                        });
                    })
                    .catch((err) => {
                        console.log("ERROR: ====", err);
                    })
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [name]: errorMessage,
            },
            data: {
                ...prevState.data,
                [name]: value,
            }   
        }));
    }

    call = (event) => {
        event.preventDefault();
        if (event.target.phonenumber.value == null ||
            event.target.phonenumber.value === undefined ||
            event.target.phonenumber.value.length !== 10) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    'phonenumber': 'Enter the mobile number.'
                }
            }));
        }

        if (this.validateForm(event.target.phonenumber.value)) {
            console.log("API Call");
            const data = {
                dial_phone_number: event.target.phonenumber.value,
                token: localStorage.token
            };
            console.log(data);
            API.post('appapi/callOut', data)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success === true) {
                        this.handleClick(TransitionUp);
                    } else if (res.data.responseCode === 4094 || res.data.responseCode === 4095 || res.data.responseCode === 4079) {
                         auth.logout(() => {
                             this.props.history.push("/");
                         });
                    }
                })
                .catch((err) => {
                    console.log("ERROR: ====", err);
                })
        }
    }

    render() {
         const {
             classes
         } = this.props;
        return (
            <React.Fragment>
                <AppDrawer />
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Call Agent
                    </Typography>
                    <form className={classes.form} onSubmit={this.call} noValidate>
                        <Autocomplete
                            id="combo-box-demo"
                            freeSolo
                            options={this.state.searchphone}
                            getOptionLabel={option => option.phonenumber}
                            onChange={this.selectChange.bind(this)}
                            renderInput={params => (
                                <TextField 
                                    {...params} 
                                    label="Phone Number" name="phonenumber"
                                    min={10}
                                    onInput = {
                                        (e) => {
                                            // e.target.value = e.target.value.replace(/[^0-9]/g, '') 
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10)
                                        }
                                }
                                fullWidth
                                required
                                type = "tel"
                                id = "phonenumber"
                                helperText = {
                                    this.state.errors.phonenumber
                                }
                                variant= "outlined"
                                autoFocus
                                onChange = {
                                    this.onChange.bind(this)
                                }
                                error = {
                                    this.state.errors.phonenumber.length >= 10 ? true : false
                                }
                                />
                            )}
                        />
                        {/* <TextField
                        margin="normal"
                        required
                        error = {
                            this.state.errors.phonenumber.length >= 10 ? true : false
                        }
                        fullWidth
                        id = "phonenumber"
                        type="text"
                        label="Phone Number"
                        name="phonenumber"
                        autoComplete="number"
                        inputProps = {
                            {
                                maxLength: 10,
                            }
                        }
                        helperText = {
                            this.state.errors.phonenumber
                        }
                        variant= "outlined"
                        autoFocus
                        onChange = {
                            this.onChange.bind(this)
                        }
                        onInput = {
                            (e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '')
                            }
                        }
                        /> */}
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        >
                        Call
                        </Button>
                    </form>
                    </div>
                    <Box mt={8}>
                    </Box>
                </Container>

                <Snackbar
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.transition}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Call is Connecting ...</span>}
                />
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(Call)