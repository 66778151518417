class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    console.log("Logout")
    localStorage.removeItem('token');
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    return localStorage.token !== undefined ? true : false;
  }
}

export default new Auth();
