import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
    withStyles
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from './../utils/API';
// Custom Component
import AppDrawer from './AppDrawer';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
// import Map from './Map';
import AddressChange from './AddressChange';
import auth from './../auth/auth';

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appBar: {
            position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

class LocationChange extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            data: {
                merchantid: null,
            },
            errors: {
                merchantid: ''
            },
            opentoast: false,
            transition: undefined,
            merchant_address: {
                address: null,
                latitude: null,
                longitude: null,
                agend_id: null
            },
            first: true
        }
    }

    validateForm = (errors) => {
         let valid = true;
         Object.values(errors).forEach(
             (val) => {
                 if (val === null || val === '') {
                     valid = false
                 }
             }
         );
         return valid;
    }

    handleClickToast = Transition => {
        this.setState({
            transition: Transition
        });
        this.setState({
            opentoast: true
        });
        console.log(this.state.open);
    };

    handleCloseToast = () => {
        this.setState({
            opentoast: false
        });
    };

    handleCloseAddress = () => {
        this.setState({
            first: true
        });
    }

    onChange(event) {
        const {
            name,
            value
        } = event.target;

        let errorMessage = "";
        switch (name) {
            case 'merchantid':
                errorMessage = value.length === 0 ? 'Enter the merchant id.' : ''
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [name]: errorMessage,
            },
            data: {
                ...prevState.data,
                [name]: value,
            }
        }));
    }

    merchantcall = (event) => {
        event.preventDefault();
        if (event.target.merchantid.value == null ||
            event.target.merchantid.value === undefined ||
            event.target.merchantid.value.length === 0) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    'merchantid': 'Enter the merchant id.'
                }
            }));
        }

        if (this.validateForm(this.state.data)) {
            const data = {
                agent_id: this.state.data.merchantid,
                token: localStorage.token
            }
             API.post('appapi/get-address', data)
                 .then((res) => {
                     if (res.data.responseCode === 200) {
                        this.setState(prevState => ({
                            merchant_address: {
                                ...prevState.merchant_address,
                                'address': res.data.address,
                                'latitude': res.data.position.latitude,
                                'longitude': res.data.position.longitude,
                                'agent_id': this.state.data.merchantid
                            },
                            first: false
                        }));
                     } else if (res.data.responseCode === 400 || res.data.responseCode === 404) {
                        this.handleClickToast(TransitionUp)
                     } else if (res.data.responseCode === 4094 || res.data.responseCode === 4095 || res.data.responseCode === 4079) {
                         auth.logout(() => {
                             this.props.history.push("/");
                         });
                     }
                 })
                 .catch((err) => {
                     console.log("ERROR: ====", err);
                 })
        }
    }

    render() {
        const {
            classes
        } = this.props;

        return (
            <React.Fragment>
                { this.state.first ? 
                     ( <React.Fragment>< AppDrawer / > < Container component = "main"
                         maxWidth = "xs" >
                        <CssBaseline />
                        <div className={classes.paper}>
                        <Typography component="h1" variant="h5">
                            Agent Location Change
                        </Typography>
                        <form className={classes.form} onSubmit={this.merchantcall} noValidate>
                            <TextField
                            margin="normal"
                            required
                            error = {
                                this.state.errors.merchantid.length === 0 ? false : true
                            }
                            fullWidth
                            id = "merchantid"
                            type="text"
                            label="Agent ID"
                            name = "merchantid"
                            value = {
                                this.state.data.merchantid
                            }
                            autoComplete="text"
                            helperText = {
                                this.state.errors.merchantid
                            }
                            variant= "outlined"
                            autoFocus
                            onChange = {
                                this.onChange.bind(this)
                            }
                            />
                            <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            >
                            Submit
                            </Button>
                        </form>
                        </div>
                        <Box mt={8}>
                        </Box>
                     </Container></React.Fragment>) : 
                     <AddressChange handleCloseAddress = {
                        this.handleCloseAddress
                    } address = {this.state.merchant_address}
                    />
                    }
                <Snackbar
                    open = {
                        this.state.opentoast
                    }
                    onClose={this.handleCloseToast}
                    TransitionComponent={this.transition}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Agent id does not found</span>}
                    />
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(LocationChange)