import axios from 'axios';
import auth from './../auth/auth';

let token = auth.isAuthenticated() ? localStorage.getItem('urlToken') : process.env.REACT_APP_TOKEN;

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    responseType: "json",
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${token}`
    },
})