import React from 'react';
import {
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import Call from '../src/components/Call';
// import Dashboard from '../src/components/Dashboard';
import Login from '../src/components/Login';
import LocationChange from '../src/components/LocationChange';
import {
  Switch,
  Route
} from "react-router-dom";

import {
  ProtectedRoute
} from "./../src/auth/protected.route";
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7ebe28',
      main: '#7ebe28',
      dark: '#7ebe28',
      contrastText: '#000',
    },
  },
  status: {
    danger: 'orange',
  },
});

export default function App() {
  return ( <div>
    <ThemeProvider theme = {
      theme
    }>
    <Switch >
    <ProtectedRoute exact path = "/"
    component = {
      Login
    }
    />
    <ProtectedRoute exact path = "/call"
    component = {
      Call
    }/>
    <ProtectedRoute exact path = "/location-change"
    component = {
      LocationChange
    }/>
    <Route path = "*"
    component = {
      () => "404 NOT FOUND"
    }/> </Switch> </ThemeProvider> </div>
  );
}