import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";
import Login from './../components/Login';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated()) {
          console.log(props.history.location.pathname === "/");
          if (props.history.location.pathname === "/") {
            return (
            <Redirect
              to={{
                pathname: "/call",
                state: {
                  from: props.location
                }
              }}
            />
          )
          }else{
            return <Component {...props} />
          }
        } else {
          if (props.history.location.pathname === "/") {
            return (
              <Login {...props} />
            )
          } else {
            return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location
                    }
                  }}
                />
            )
          }
        }
      }}
    />
  )
}
