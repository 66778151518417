import React, {Component}from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export class MapContainer extends Component {
	constructor( props ){
		super( props );
		this.state = {
			address: this.props.address.address,
			mapPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			markerPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			showingInfoWindow: false,
		}
		this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
		this.currentLocation = this.currentLocation.bind(this);
	}
	
	currentLocation = () => {
		if(navigator.geolocation){
			navigator.geolocation.getCurrentPosition(
				position => {
					let geocoder = new window.google.maps.Geocoder();
					geocoder.geocode({
						location: {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						}}, (results, status) => {
						if (status === 'OK') {
							const address = results[0].formatted_address
							this.setState( {
								address: ( address ) ? address : '',
								markerPosition: {
									lat: position.coords.latitude,
									lng: position.coords.longitude
								},
								mapPosition: {
									lat: position.coords.latitude,
									lng: position.coords.longitude
								},
								showingInfoWindow: true
							} )
							this.props.addressChange(this.state);
						} else {
							window.alert('Geocode was not successful for the following reason: ' + status);
						}
					});
				},
				error => this.handleLocationError(error), {
					enableHighAccuracy: true,
					timeout: 1000,
					maximumAge: 1000
				}
			);
		} else {
			alert('Geolocation is not supported by this browser.')
		}
	}

	handleLocationError = (error) => {
		switch(error.code) {
			case error.PERMISSION_DENIED:
				alert("User denied the request for Geolocation.")
				break;
			case error.POSITION_UNAVAILABLE:
				alert("Location information is unavailable.")
				break;
			case error.TIMEOUT:
				alert("The request to get user location timeout.")
				break;
			case error.UNKNOWN_ERROR:
				alert("An unknown error occurred.")
				break;
			default:
				alert("An unknown error occurred.")
		}
	}

	onMarkerClick = (props, marker, e) =>
		this.setState({
			showingInfoWindow: true
		});

	onMarkerDragEnd = (props, marker) => {

		let newLat = marker.position.lat(),
			newLng = marker.position.lng();

		let geocoder = new props.google.maps.Geocoder();
			
		geocoder.geocode({location: {
			lat: newLat,
			lng: newLng
		}}, (results, status) => {
			if (status === 'OK') {
				const address = results.length !== 0 ? results[0].formatted_address : 'Unknown address';
				this.setState( {
					address: ( address ) ? address : '',
					markerPosition: {
						lat: newLat,
						lng: newLng
					},
					mapPosition: {
						lat: newLat,
						lng: newLng
					},
					showingInfoWindow: true
				})
				this.props.addressChange(this.state);
			} else {
				window.alert('Geocode was not successful for the following reason: ' + status);
			}
		});
	};
  
	render() {
	  return (
		<Map google={this.props.google}
		 zoom={this.props.zoom}
		 onReady={(mapProps, map) => {
			this.setState({
				showingInfoWindow: true
			});
		 }}
		 initialCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}>
		  <Marker
		  		onClick={this.onMarkerClick}
				draggable={true}
				position={this.state.markerPosition}
				onDragend={this.onMarkerDragEnd}
				name={this.state.address} />
		  <Fab size="medium" onClick={this.currentLocation} color="secondary" aria-label="add" style={
			{
				height: "50px",
				width: "50px",
				position: "absolute",
				top: "0",
				bottom: "0",
				margin: "auto",
				right: "0"
			}
		}>
			< MyLocationIcon style={{ color: "white"}} / >
		</Fab>
		  <InfoWindow
			position={{ lat: ( this.state.markerPosition.lat + 0.0018 ), lng: this.state.markerPosition.lng }}
			visible={this.state.showingInfoWindow}>
			  <div>
			  	<span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
			  </div>
		  </InfoWindow>
		  <GooglePlacesAutocomplete
			 renderInput={(props) => (
				<div style={{marginTop: 57, background: 'white'}}>
					<TextField id="filled-basic" fullWidth InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
        }} label="Search Address" variant="filled" {...props} />
				</div>
			 )}
		  	 autocompletionRequest={{
				componentRestrictions: {
				  country: ['in'],
				}
			  }}
			  initialValue={this.state.address}
			  debounce={300}
			  onSelect={({ place_id }) => {
				geocodeByPlaceId(place_id)
					.then(results => {
						const address = results.length !== 0 ? results[0].formatted_address : 'Unknown address';
						this.setState( {
							address: ( address ) ? address : '',
							markerPosition: {
								lat: results[0].geometry.location.lat(),
								lng: results[0].geometry.location.lng()
							},
							mapPosition: {
								lat: results[0].geometry.location.lat(),
								lng: results[0].geometry.location.lng()
							},
							showingInfoWindow: true
						})
					})
					.catch(error => console.error(error));
			 	 }
			  }
			/>
		</Map>
	  )
	}
  }

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_MAP_TOKEN)
})(MapContainer)