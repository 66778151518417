import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  withStyles
} from '@material-ui/core/styles';
import MapContainer from './Map';
import API from './../utils/API';
import auth from './../auth/auth';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

class LocationChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchant_address: undefined,
      fulladdress: undefined,
      open: false,
      transition: undefined
    }
  }

  componentDidMount() {
    
  }

  save = () => {
    console.log("API Call");
    console.log(this.props.address);
    if (this.state.fulladdress.address !== undefined && this.state.fulladdress.address !== null) {
      const data = {
      dial_phone_number: this.state.phonenumber,
      token: localStorage.token,
      address: this.state.fulladdress.address,
      agent_id: this.props.address.agent_id,
      position: {
        latitude: this.state.fulladdress.markerPosition.lat,
        longitude: this.state.fulladdress.markerPosition.lng
      },
    };
    API.put('appapi/update-address', data)
      .then((res) => {
        console.log(res.data);
        if(res.data.responseCode === 200){
           this.handleClick(TransitionUp);
        } else if (res.data.responseCode === 4094 || res.data.responseCode === 4095 || res.data.responseCode === 4079) {
          auth.logout(() => {
            this.props.history.push("/");
          });
        }
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      })
    }
  }

  addressChange = (address) => {
    console.log("Address Change");
    this.setState({
      fulladdress: address
    })
  }

  handleClick = Transition => {
    this.setState({
      transition: Transition
    });
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render(){
    var handleCloseAddress = this.props.handleCloseAddress;
    const {
      classes
    } = this.props;
    return(
      <React.Fragment>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => handleCloseAddress()} aria-label="close">
              <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
              Location Change
              </Typography>
              <Button autoFocus color="inherit" onClick={this.save}>
              save
              </Button>
          </Toolbar>
        </AppBar>
        <MapContainer google={this.props.google} 
            center = {
                {
                    lat: parseFloat(this.props.address.latitude),
                    lng: parseFloat(this.props.address.longitude)
                }
            }
            address = { this.props.address }
            height='100%'
            addressChange = {
              this.addressChange
            }
            zoom={15} />
        <Snackbar
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={this.transition}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Address Change Successfylly</span>}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(LocationChange)