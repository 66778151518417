import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { RemoveRedEye } from '@material-ui/icons';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import PropTypes from 'prop-types';
import React, { Component } from 'react';

// const styles = theme => ({
//   eye: {
//     cursor: 'pointer',
//   },
// });

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true,
    };
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  render() {
    // const { classes } = this.props;
    const { passwordIsMasked } = this.state;
    return (
      <TextField
        type={passwordIsMasked ? 'password' : 'text'}
        {...this.props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
                {/* <RemoveRedEye
                // className={classes.eye}
                style = {
                  {
                    cursor: 'pointer'
                  }
                }
                onClick={this.togglePasswordMask}
              /> */}
                {passwordIsMasked ? 
                    <VisibilityOffIcon
                        style = {
                          {
                            cursor: 'pointer'
                          }
                        }
                        onClick={this.togglePasswordMask}
                    /> : 
                    <RemoveRedEye
                        style = {
                          {
                            cursor: 'pointer'
                          }
                        }
                        onClick={this.togglePasswordMask}
                    />}
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

// PasswordInput.propTypes = {
//   classes: PropTypes.object.isRequired,
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.func.isRequired,
// };

export default PasswordInput;